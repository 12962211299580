<template>

    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                        <h3 style='font-weight: normal' class='mb-1'>{{ invoiceTypeFriendlyName }}</h3>
                        <div class='text-700'>{{ activeProjectName }}</div>
                    </div>
                </div>


                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3 style='font-weight: normal'>{{ invoiceTypeFriendlyName }} Fatura / Makbuz</h3>

					<p style='font-weight:normal;'>

					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='col-12'>

                    

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Giriş Tipi</label>
                        <div class='col-12 md:col-10'>
                            <b>{{ invoiceTypeFriendlyName }}</b>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İşlenme Tarihi</label>
                        <div class='col-12 md:col-10'>
                            <b>{{ formatDate(dataItem.insertDate)}}</b>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Dönem<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true' :showButtonBar='true' v-model='selectedPeriod' @date-select='periodChanged'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Evrak Tipi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='documentType' v-model='dataItem.documentType' :options='documentTypeList'
                                      optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Firma Seç<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <AutoComplete v-model="selectedCompany" :suggestions="filteredCompanies" :delay="200" @complete="companySearched"
                                          :virtualScrollerOptions="{ itemSize: 38 }"  option-Label="name" dropdown @item-select='companySelected' />
                        </div>
                    </div>

                    

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Evrak Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='documentDate' dateFormat='dd.mm.yy' autocomplete='off' :showIcon='true'
                                      :showButtonBar='true' v-model='dataItem.documentDate'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.documentType == "Fatura"'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Fatura No</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' type='text' autocomplete='off' v-model='dataItem.documentNumber' />
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.documentType == "Fatura"'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İrsaliye No</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' type='text' autocomplete='off' v-model='dataItem.documentNumber2' />
                        </div>
                    </div>

                   

                    <div class='field grid mb-5' v-if='dataItem.invoiceType == "InputInvoice"'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Kategori Giren<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='inputCategory' v-model='dataItem.category' :options='inputCategoryList' optionLabel='name'
                                      option-value='value' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.invoiceType == "OutputInvoice"'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Kategori Çıkan<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='outputCategory' v-model='dataItem.category' :options='outputCategoryList'
                                      optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Açıklama</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' type='text' autocomplete='off'  v-model='dataItem.description' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>KDV Toplamı</label>
                        <div class='col-12 md:col-10'>
                            <InputNumber id='taxTotal' type='text' autocomplete='off' inputId="locale-tr" :minFractionDigits="2" v-model='dataItem.taxTotal' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Tutar<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputNumber id='name' type='text' autocomplete='off' inputId="locale-tr" :minFractionDigits="2" v-model='dataItem.invoiceTotal' />
                        </div>
                    </div>

                    

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Toplam Tutar</label>
                        <div class='col-12 md:col-10'>
                            <b>{{ totalWithTax }} TL</b>
                        </div>
                    </div>

                    <div class='field grid' v-if='dataItem.id > 0'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Dosya Ekle' icon='pi pi-upload' @click='save'
                                    v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Kaydet' icon='pi pi-save' @click='save'
                                    v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage, showSuccessMessage,
        showValidationMessage,
} from '../../components/shared/toast/toastFunction';

import store from '@/store';
import moment from 'moment/moment';
import data from 'bootstrap/js/src/dom/data';
import CompanyService from '@/services/companyService';
import { getInvoiceInputCategories, getInvoiceOutputCategories } from '@/pages/common/commonFunctions';
import InvoiceService from '@/services/invoiceService';
import { getActiveProjectId, getUserId, normalizeDate } from '@/pages/common/commonConstantFunctions';
import { formatNumber } from 'chart.js/helpers';

export default {
    name: 'AddInvoiceForm',
    _invoiceService: null,
    _companyService: null,
    props: {
        invoiceType: {},
    },
    created() {
        this._companyService = new CompanyService();
        this._invoiceService = new InvoiceService();
        this.activeProjectName = store.getters.getActiveProject.name;
        this.dataItem.invoiceType = this.invoiceType;
        this.dataItem.insertDate =  moment().toDate();
        this.dataItem.period =  this.formatPeriod(moment().toDate());
        this.selectedPeriod = moment().toDate();
        this.dataItem.projectId = getActiveProjectId();
        this.dataItem.managerUserId = getUserId();
    },
    async mounted() {
        this.inputCategoryList = await getInvoiceInputCategories();
        this.outputCategoryList = await getInvoiceOutputCategories();

    },
    computed: {
        data() {
            return data
        },
        invoiceTypeFriendlyName() {
            if(this.dataItem.invoiceType == "InputInvoice")
                return "Gider";
            else {
                return "Gelir";
            }
        },
        totalWithTax() {
            return  formatNumber(parseFloat(this.dataItem.taxTotal) + parseFloat(this.dataItem.invoiceTotal));
        }
    },
    data() {
        return {
            activeProjectName: '',
            selectedPeriod:{},
            isProcessing: false,
            dataItem: {
                id:0,
                companyId: '0',
                insertDate: '',
                isActive: '',
                documentDate: '',
                documentNumber: '',
                documentNumber2:'',
                invoiceTotal: 0,
                taxTotal: 0,
                isPaid: '',
                category: '',
                description: '',
                invoiceType: '',
                period: '',
                managerUserId: 0,
                projectId: 0,
                documentType: 'Fatura'
            },
            selectedCompany:null,
            companyLists: [],
            filteredCompanies:[],
            inputCategoryList:[],
            outputCategoryList:[],
            documentTypeList:[
                {
                    name:"Fatura"
                },
                {
                    name:"Makbuz"
                }
            ],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        cityChanged() {
        },
        async save() {

            if (this.validateForm()) {

                this.dataItem.documentDate = normalizeDate(this.dataItem.documentDate);
                this.isProcessing = true;
                this.dataItem.projectId = store.getters.getActiveProject.id;

                const insertResponse = await this._invoiceService.insertInvoice(this.dataItem);
                if (insertResponse.isSuccess) {
                    showSuccessMessage(this, "Ekleme işlemi başarılı");
                    setTimeout( async () => await this.$router.push({
                        name: 'editInvoice',
                        params: { invoiceType: this.dataItem.invoiceType, invoiceId: insertResponse.data }
                    }), 2000);

                } else {
                    this.isProcessing = false;
                    showErrorMessage(this, 'Eklemede sorun ile karşılaşıldı');
                }
            }
        },
        validateForm() {
            if (this.dataItem.companyId == '0') {
                showValidationMessage(this, 'Firma Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.period == '') {
                showValidationMessage(this, 'Dönem Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.documentType == '') {
                showValidationMessage(this, 'Evrak Tipi Boş Bırakılamaz');
                return false;
            }


            if (this.dataItem.documentDate == '') {
                showValidationMessage(this, 'Evrak Tarihi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.category == '') {
                showValidationMessage(this, 'Kategori Alanı Boş Bırakılamaz');
                return false;
            }


            if (this.dataItem.invoiceTotal == '0' || this.dataItem.invoiceTotal == '0') {
                showValidationMessage(this, 'Fatura Tutarı Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        async periodChanged() {
            this.dataItem.period = this.formatPeriod(this.selectedPeriod);
        },

        formatPrice(value) {
            // let val = (value / 1).toFixed(2).replace('.', ',');
            // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return value;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        formatPeriod(value) {
            let newDate = moment(value);
            return newDate.format('MM-yy');
        },
        companySelected(event) {
            this.dataItem.companyId =event.value.id;
            this.dataItem.companyName = event.value.name;
        },
        companySearched(event) {
            setTimeout(async () => {
                if (!event.query.trim().length) {
                    this.filteredCompanies = [];
                } else {

                    let companyResponse = await this._companyService.searchCompanies(event.query.toLowerCase());
                    if (companyResponse.isSuccess) {
                        this.filteredCompanies = companyResponse.data;
                    }

                }
            }, 250);
        }
    },
};

</script>


<style scoped>

</style>
